/* General section styling */
.advert {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  gap: 4rem; /* Equivalent to gap-16 */
  padding: 1.5rem 1rem; /* Equivalent to px-6 */
  height: auto;
  overflow: hidden;
  background-color: #ffffff; /* bg-white */
  color: #1e293b; /* dark:bg-slate-800 */
  padding-top: 6rem; /* py-24 */
  padding-bottom: 6rem;
}

@media (min-width: 640px) { /* sm: */
  .advert {
    padding-right: 2.5rem; /* pr-10 */
    padding-left: 2.5rem; /* pl-10 */
    padding-top: 5rem; /* sm:py-20 */
    padding-bottom: 5rem;
  }
}

@media (min-width: 768px) { /* md: */
  .advert {
    grid-template-columns: repeat(2, 1fr); /* md:grid-cols-2 */
  }
}

/* Image container styling */
.advert-image-container {
  display: flex;
  justify-content: flex-start; /* Center the image horizontally */
  align-items: flex-start; /* Center the image vertically */ 
  width: 120%; /* Full width of the container */
  position: relative; /* Relative positioning to handle absolute child positioning */
  height: auto; /* Height will adjust based on content */

}

.homecustom-image {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  
}
@media (max-width: 700px) { /* md: */
  .homecustom-image {
    padding-right: 5rem; /* Equivalent to md:p-8 */ 
    border-radius: 2rem 2rem 2rem 2rem; /* Top-left, Top-right, Bottom-right, Bottom-left */
  }
}

@media (min-width: 768px) { /* md: */
  .homecustom-image {
    padding: 2rem; /* Equivalent to md:p-8 */

    border-radius: 5rem; /* Equivalent to rounded-2xl */
    box-sizing: border-box; /* Ensure padding does not affect width calculation */
  }
}

@media (min-width: 1024px) { /* lg: */
  .homecustom-image {
    padding: 4rem; /* Equivalent to lg:p-8 */
    border-radius: 5rem; /* Equivalent to rounded-2xl */
    box-sizing: border-box; /* Ensure padding does not affect width calculation */
  }
}


/* Content styling */
.advert-content {
  display: flex;
  flex-direction: column;
  margin-left: auto; /* lg:ml-auto */
  gap: 2rem; /* space-y-8 */
  padding-left: 1rem; /* lg:pl-4 */
  padding-top: 1rem; /* lg:pt-4 */
  padding: 1rem; /* p-4 */
}

@media (min-width: 640px) {
  /* sm: */
  .advert-content {
    padding: 1.5rem; /* sm:p-6 */
  }
}

@media (min-width: 768px) {
  /* md: */
  .advert-content {
    padding: 2rem; /* md:p-8 */
  }
}

@media (min-width: 1024px) {
  /* lg: */
  .advert-content {
    padding: 2.5rem; /* lg:p-10 */
  }
}

.advert-title {
  color: #3b82f6; /* text-blue-600 */
  font-weight: 600; /* font-semibold */
}

.advert-heading {
  font-size: 2.25rem; /* text-4xl */
  font-weight: 700; /* font-bold */
  color: #1e293b; /* text-slate-900 */
  margin-bottom: 1rem; /* mb-4 */
}

.advert-description {
  color: #4b5563; /* text-gray-700 */
  margin-bottom: 1.5rem; /* mb-6 */
}

.advert-features {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* space-y-8 */
}

.feature-item {
  display: flex;
  align-items: flex-start; /* items-start */
}

.feature-icon {
  color: #3b82f6; /* text-icon-marketing */
  margin-right: 1rem; /* mr-4 */
}

.feature-heading {
  font-size: 1.25rem; /* text-xl */
  font-weight: 600; /* font-semibold */
}

.feature-description {
  color: #4b5563; /* text-gray-700 */
}
