.homecontact-container {
    background-color: white;
    padding: 4rem 1.5rem;
    text-align: justify;
  }
  
  .homecontact-header {
    margin: 0 auto;
    max-width: 40rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  
  .homecontact-title {
    font-size: 2rem;
    font-weight: bold;
    color: black;
  }
  
  .homecontact-description {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }
  
  .homecontact-details {
    margin-top: 4rem;
    max-width: 40rem;
    margin: 0 auto;
  }
  
  .homecontact-item {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .homecontact-icon {
    font-size: 1.5rem;
    color: #ffffff;
    background-color: #4a90e2;
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  
  .icon {
    font-size: 1.5rem;
  }
  
  .homecontact-info {
    flex: 1;
  }
  
  .homecontact-subtitle {
    font-size: 1.125rem;
    font-weight: bold;
    color: black;
  }
  
  .homecontact-text {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0.5rem;
  }
  
  .homecontact-link {
    font-size: 0.875rem;
    font-weight: 600;
    color: #00aaff;
    display: flex;
    align-items: justify;
    gap: 0.25rem;
    text-decoration: none;
  }
  
  .homearrow-icon {
    transition: transform 0.3s;
  }
  
  .homecontact-link:hover .arrow-icon {
    transform: translateX(0.25rem);
  }
  