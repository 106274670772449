.aboutstart-bg-gradient {
  background-image: linear-gradient(to bottom, #1E293B, #475569);
  position: relative;
}

.aboutstart-relative-section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @media (min-width: 640px) {
    padding-top: 5rem;
    padding-bottom: 4rem;
  }
  @media (min-width: 1024px) {
    padding-bottom: 6rem;
  }
}

.aboutstartbackground-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #143977; /* #143977 with 50% opacity */
  z-index: 15;
} 
.aboutstartcontent-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  position: relative;
  z-index: 20;
}

.aboutstarttext-container {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.aboutstarttitle-primary {
  font-size: 2.25rem;
  font-weight: bold;
  @media (min-width: 640px) {
    font-size: 4rem;
  }
}

.aboutstarttext-gradient {
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(to right, #38BDF8, #ffffff);
}

.aboutstartdescription {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  @media (min-width: 640px) {
    font-size: 1.25rem;
  }
}

.aboutstartdiscover-button {
  display: inline-flex;
  margin-top: 2.5rem;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0ea5e9;
  font-weight: 500;
  border: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  @media (min-width: 1280px) {
    font-size: 1.125rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  transition: background-color 0.5s ease;
}

.aboutstartdiscover-button:hover {
  background-color: #0284C7;
}

.aboutstarticon-container {
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-right: -0.5rem;
}

.aboutstartfeature-grid {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 3rem;
  text-align: left;
  gap: 3rem 1.5rem;
  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 0;
    padding-right: 0;
  }
}

.aboutstartfeature-item {
  display: flex;
  align-items: start;
}

.aboutstartfeature-icon {
  color: #0ea5e9;
  flex-shrink: 0;
  font-size: 1.125rem;
}

.aboutstartfeature-text {
  margin-left: 0.75rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}
