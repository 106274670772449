.aboutcontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
  padding: 0 1rem; /* Adjust the horizontal padding as needed */
}

.aboutfeature-section {
  padding-top: 2.5rem; /* 40px */
  padding-bottom: 2.5rem; /* 40px */
  background-color: #ffffff; /* White */
}

/* Adjust padding for small screens and above */
@media (min-width: 640px) {
  /* sm */
  .aboutfeature-section {
    padding-top: 4rem; /* 64px */
    padding-bottom: 4rem; /* 64px */
  }
}

/* Adjust padding for large screens and above */
@media (min-width: 1024px) {
  /* lg */
  .aboutfeature-section {
    padding-top: 6rem; /* 96px */
    padding-bottom: 6rem; /* 96px */
  }
}

/* Dark mode background color */
@media (prefers-color-scheme: dark) {
  .aboutfeature-section {
    background-color: #1e293b; /* Slate-800 */
  }
}
.aboutfeature-section-dark {
  background-color: #ffffff;
  /* color: white; */
}
.aboutfeature-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.heading-2xl {
  font-size: 2.5rem; /* Adjust size as needed */
  font-weight: bold;
  text-align: center;
}
.aboutfeature-item {
  width: 100%;
  padding: 1rem;
}

@media (min-width: 640px) {
  .aboutfeature-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .aboutfeature-item {
    width: 33.33%;
  }
}

@media (min-width: 1024px) {
  .aboutfeature-item {
    width: 25%;
  }
}

.aboutfeature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #f1f5f9;
}

.aboutfeature-icon-blue {
  background-color: #e0f2fe;
}

.aboutfeature-icon-orange {
  background-color: #fef2f2;
}

.aboutfeature-icon-green {
  background-color: #f0fdf4;
}

.aboutfeature-icon-purple {
  background-color: #f5f3ff;
}
.aboutfeature-icon-blue {
  background-color: #bae4ff;
}

.aboutfeature-icon-text {
  font-size: 1.5rem;
}

.aboutfeature-icon-text-blue {
  color: #0284c7;
}

.aboutfeature-icon-text-orange {
  color: #f97316;
}

.aboutfeature-icon-text-green {
  color: #10b981;
}

.aboutfeature-icon-text-purple {
  color: #7e22ce;
}

.aboutfeature-title {
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.aboutfeature-description {
  margin-top: 0.5rem; /* Equivalent to Tailwind's mt-2 (8px) */
  font-size: 1.125rem; /* Equivalent to Tailwind's text-lg (18px) */
  line-height: 2rem; /* Equivalent to Tailwind's leading-8 (32px) */
  color: #374151; /* Equivalent to Tailwind's slate-700 color */
  text-align: justify;
}
